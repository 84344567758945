@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

$navbarsize:20px;
$headers:60px;
$paragraphs:22px;

// Font Size
//
// @include font-size($size);
//
// Set the font-size in pixels and it will calculate the font-size in rem.
//
// $size = 16px - Font size in pixels.
//
// Styleguide _Nuclides.Mixins.FontSize

@mixin font-size($size) {
	font-size: $size;
	font-size: calculaterem($size);
}


// Line height
//
// line-height: line-height(55px, 70px);
//
// Set the line-height based on the font-size and line-height in pixels
//
// $font-size = 55px - Font size in pixels.
// $line-height = 70px - Line height in pixels.
// Output: 1.27273
//
// Styleguide _Nuclides.Mixins.LineHeight

@function line-height($font-size, $line-height) {
	@return $line-height / $font-size * 1em;
}