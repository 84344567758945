.projects {
	margin: 0 auto;

	&__columns {
		max-width: 1440px;
		margin: 0 auto;
		display: block;

		@include breakpoint-sm {
			grid-template-columns: 1fr 1fr;
			display: grid;
		}

		@include breakpoint-md {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}

.project {
	&__column {
		&:first-child {
			@include breakpoint-sm {
				grid-column-start: 1;
				grid-column-end: 2;
				grid-row-start: 1;
				grid-row-end: 1;
			}
		}

		&:first-child {
			.project__content {
				padding-bottom: 10px;

				@include breakpoint-md {
					height: 200px;
				}
			}
		}

		@include breakpoint-md {
			&:first-child {
				grid-column-end: 1;
			}
		}
	}

	&__card {
		box-shadow: 1px 6px 45px 0 #0a0a0a;
		height: 100%;
		border-radius: 5px;

		@include breakpoint-sm {
			margin: 0 auto;
		}

		@include breakpoint-lg {
			margin: 0 20px;
		}
	}

	&__card-header {
			box-shadow:	none;	
	}

	&__card-header-title {
		font-size: 20px;
		color: $darkblue;
	}
	

	&__card-image {
		height: 300px;

		figure,
		img {
			height: 100%;
			width: 100%;
		}
	}

	&__card-content {
		padding: 0.5em 1em 1em 1em;
		font-size: 1em;
		margin: 0 auto;
		border-top:2px solid #68757a2e;
	}

	&__content {
		height: auto;
		padding-bottom: 10px;
		color:#2b2d42;
		padding-top: 5px;
		
		@include breakpoint-sm {
			height: 200px;
		}
	}


	&__tag, .tag {
		margin: 10px 5px 5px 0;
		background-color: $darkblue!important;
		color: $white!important;
		font-weight: bold;
	}

	&__card-footer {
		margin-top: 5px;
		border-top: 2px solid $Nevada;
		display: block;

		.button {
			transition: 0.3s;
			&:hover {
				background: $light-blue;
			}
		}

		.badges {
			@include breakpoint-sm {
				height: 100px;
			}
			@include breakpoint-lg {
				height: 80px;
			}
		}
	
	}

	&__button-wrapper {
		display: flex;
	}

	&__card-footer-item {
		border-radius: 0;
		margin-top: 20px;

		&:nth-child(2) {
			background-color: $Nevada;
			border-color: transparent;
			color: $white;
		}

		&:nth-child(1) {
			background-color: $darkblue;
			border-color: transparent;
			color: $white;
		}

		&:nth-child(3) {
			background-color: $darkblue;
			border-color: transparent;
			color: $white;
		}
	}
}
