.skill {
	margin-top: 20px;

	&__thumbnail {
		margin: 0 auto;
		width: 50%;

		@include breakpoint-sm-big-max {
			width: 30%;
		}

		@include breakpoint-max-sm {
			width: 50%;
		}
	}

	&__image {
		width: 100%;
	}
}

.skills {
	max-width: 1440px;
	margin: 0 auto;
	&__columns {
		margin-right: 0;
	}
}
