.button {
	cursor: pointer;

	&--linkedIn {
		background-color: $linkedIn;
		color: $white;

		&:hover {
			color: $white;
		}
	}
	&--instagram {
		background-color: $instagram;
		color: $white;

		&:hover {
			color: $white;
		}
	}
}
