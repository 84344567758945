.service {
	margin-top: 20px;

	&__thumbnail {
		margin: 0 auto;
		width: 50%;

		@include breakpoint-sm-big-max {
			width: 30%;
		}

		@include breakpoint-max-sm {
			width: 50%;
		}
	}

	&__center {
		margin: 0 auto;
	}

	&__image {
		width: 100%;
	}
	
	&__text,&__price {
		text-align: center;
		font-weight: bold;
	}
}
