.title {
	font-size: $size-4;
	text-align: center;
	margin-top: 25px;
	color: $beige-light;
	margin-bottom: 0;
	text-transform: uppercase;

	@include breakpoint-sm {
		font-size: $size-3;
	}
}
