section#projects {
  @include breakpoint-max-sm {
    padding: 0 0 3em 0;
  }
}
section#skills,
section#services {
  @include breakpoint-max-sm {
    padding: 1em 0 3em 0;
  }
}

section#projects {
	padding: 1rem 0;
}

// .section {
//   padding: 1rem 1.5rem;
// }

section#skills,
section#contact {
  background: $beige-light;
  color: $darkblue;

  .title {
    color: $darkblue;
  }
}


section#projects,
section#services {
  color: $beige-light;
  background: $darkblue;
  @include breakpoint-md {
    // height: calc(100vh);
  }
}

section#projects {
	background-image: url('/img/blob-projects.svg');
	background-size: cover;
    background-position: center;
	background-attachment: fixed;

	.title {
		color: $darkblue;
	  }
}

section#aboutme {
  padding: 0;
	position: relative;
	background-image: url('/img/coding.jpeg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100vw;
	height: 100vh;  
	background-attachment: inherit;
	@include breakpoint-md {
		background-attachment: fixed;
	}


	&:before {
		content: '';
		width: 100%;
		height: 100%;
		background: radial-gradient(black, transparent);
		position: absolute;
	}
}
