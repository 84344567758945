.contact {
	flex-wrap: wrap;
	display: flex;
	max-width: 1440px;

	@include breakpoint-sm {
		width: 70%;
    margin: 0 auto;
	}
	@include breakpoint-md {
		width: 100%;
		display: flex;
		flex-wrap: inherit;

	}

	.bulma-delete-mixin {
		@include delete;
	}

	.mapContainer {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70%;
    margin-top: 40px;
    margin-bottom: 20px;
    border-radius: 20px;

    .mapboxgl-ctrl-bottom-left {
      display: none;
    }

		.mapboxgl-marker {
			cursor: pointer;
		}

    .mapboxgl-ctrl-bottom-right {
      display: none;
    }

		.mapboxgl-canvas {
			&:focus {
				outline:0px;
			}


		}
  }

	&__mapwrapper {
		order: 2;
		width: 100%;
		display: grid;
		
		@include breakpoint-md {
			order:-1;
			width: 60%;
		}
	}

	.contact-form {
		width: 50%;

		.has-text-success {
			position: absolute;
		}	
		
		@include breakpoint-md {
			margin-left: 20px;
		}
		
		@include breakpoint-max-md-alternative {
			width: 100%;
		}

		&__field {
			color: $darkblue;
			font-size: 1.5em;

			&:not(:last-child) {
				margin-bottom: 1.25rem;
			}
		}

		&__label {
			font-size: 0.8em;

			@include breakpoint-max-md-alternative {
				font-size: 0.8em;
			}
		}

		&__input {
			background-color: $darkblue;
			color: $beige-light;
			height: 60px;
		}

		::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: $beige-light;
			opacity: 1; /* Firefox */
			font-family: 'Poppins', sans-serif;
		}
		
		.error {
			position: absolute;
			margin-top: 1px;
			font-size: 0.75rem;
		}

		.errorinput {
			border: 3px solid red;
		}

		textarea {
			resize: none;
		}

		.is-link {
			color: $beige-light;
			background: $darkblue;
			font-size: 1.5rem;
			padding-left: 1em;
			padding-right: 1em;
			border-radius: 4px;
			font-family: 'Poppins', sans-serif;
			transition: background 0.3s linear;

			@include breakpoint-max-md-alternative {
				font-size: 1.2em;
			}

			&:hover,
			&:focus {
				color: $darkblue;
				background: $beige-light;
				border: 2px solid $darkblue;
			}
		}
	}

	.button.is-grey {
		&:focus {
			color: $darkblue;
		}
	}
}