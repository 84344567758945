body {
	font-family: 'Poppins', sans-serif;
}

html {
	scroll-behavior: smooth;
	scroll-padding-top: 50px;
	background: $darkblue;
}

.grecaptcha-badge {
	z-index: 9999;
}
