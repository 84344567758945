.navbar {
	font-size: $navbarsize;
	font-weight: bold;
	width: 100%;
	background: transparent;
	left: 0;
	position: absolute;
	right: 0;
	z-index: 30;
	top:0;

	@include breakpoint-max-md {
		background: $beige-light;
		border-bottom: 2px solid $darkblue;
	}

	&__brand {
		z-index: 1000;
		
		a {
			color: $beige-light;

			&:hover {
				&:after {
					@include breakpoint-max-md  {
						border-bottom: 0px solid transparent;
						display: none;
					}
				}
			}

			@include breakpoint-lg {
				border-right: 0;
			}

			@include breakpoint-max-md {
				color: $beige-darker;
			}
		}
	}

	.navbar-menu.desktop {
		@include breakpoint-max-md {
			display: none;
		}

		.navbar-start {
			margin: 0 auto;
		}
	}

	.navbar-burger {
		border: 0;
		background: $beige-light;

		span {
			background: transparent;
		}

		i {
			font-size: 1.3rem;
		};
	}

	div#navMenu {

		@include breakpoint-md-alternative  {
			width: 100%;
			position: absolute;
		}
		@include breakpoint-max-md {
			overflow: hidden;
		}
	}

	.navbar-menu {
		overflow: hidden;

		&__item {
			color: $beige-light;
			border: 3px solid transparent;
			display: inline-block;
			text-transform: uppercase;

			&:after {
				display: block;
				content: "";
				border-bottom: solid 3px $white;
				transition: all 300ms ease-in-out;
				transform: scaleX(0);
			}


			@include breakpoint-max-md {
				color: $beige-darker;
				display: block;
				font-size: 20px;
			}

			&:hover,
			&:focus {
				background: transparent;

				@include breakpoint-md-alternative {
					color: $beige-light;
				}
			}

			&:hover:after,
			&:focus:after {
				transform: scaleX(1);
			}

			&--is-active {
				&::after {
					transform: scaleX(1);
				}
			}
		}
	}

	// Modifiers navbar
	@include breakpoint-md-alternative {
		&--dark {
			transition: 0.25s;
			background-color: $darkblue;
			box-shadow: 0 10px 12px 0 #01010157;
			position: fixed;
		}

		&--transparant {
			transition: 0.25s;
			background-color: transparent;
			position: absolute;
		}
	}
}
