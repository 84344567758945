// brand colors
$grey: #707070;
$darkblue: #2b2d42;
$beige-light: #edf2f4;
$Nevada: #68757A;
$beige-darker: #5c5c5c;
$light-blue:#4b4f72;
$linkedIn: #007cc1;
$instagram: #E60000;
$white: #fff;
$success: #E60000;
