.aboutme-wrapper {
	height: 100%;
}

.aboutme {
  height: 100%;
  padding: 200px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: $beige-light;

	@include breakpoint-md {
		height: 100vh;
	}

  @include breakpoint-max-md {
    padding: 10px;
  }

  @include breakpoint-lg {
    max-width: 1200px;
  }

  &__title {
		font-size: 1rem;

    @include breakpoint-sm {
			font-size: 1.5rem;
    }
  }

  &__content {
    text-align: center;
  }

  &__text {
    font-size: $paragraphs;

    @include breakpoint-max-md {
      font-size: 18px;
    }
    margin-top: 1.5rem;
    margin-bottom: 50px;
  }

  &__profilephoto {
    margin: 0 auto;

    img {
      width: 150px;
      height: 150px;
			object-fit: cover;
      border-radius: 100px;
      cursor: -webkit-grabbing;

			@include breakpoint-sm {
				width: 200px;
				height: 200px;
			}

			
    }
  }

  &__button {
		display: inline-block;
		transition: all 0.3s ease-in-out;
		-webkit-transition: 0.3 0.3 ease-in-out;
		-moz-transition: all 0.3 ease-in-out;	
		-o-transition: all 0.3 ease-in-out;

    &:hover {
			transform: translateY(10px);
			-webkit-transform: translateY(10px);
			-ms-transform: translateY(10px);
    }
  }

  &__icon {
    border: 0;
    color: $beige-light;
    font-size: 16px;
    margin-bottom: 45px;

    &:hover {
      color: $link;
    }
  }
}

@include breakpoint-max-md {
  section#aboutme {
    .aboutme .content p {
      font-size: 16px;
    }
  }
}
