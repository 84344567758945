.footer {
  background: $beige-light;

	&__info {
		background: $darkblue;
			@include breakpoint-md {
				padding-top: 100px;
				clip-path: polygon(0 0, 0% 0, 70% 100%, 0 calc(100% - 0vw));
			}
	}
  &__icons {
    padding: 20px;

    a {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .button {
      &:focus {
        border: 2px solid $darkblue;
      }
    }
  }
}


